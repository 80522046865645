
















































import { Component, Vue } from 'vue-property-decorator'
import { userModule, stateModule } from '@/store'

import ProfileMenu from '@/components/ProfileMenu.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'

@Component({
  components: {
    ProfileMenu,
  },
})
export default class Navigation extends Vue {
  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get isLoggedIn(): boolean {
    return userModule.isLoggedIn
  }

  private get name(): string {
    return userModule.user ? userModule.user.username : ''
  }

  private get canSeeImplementations(): boolean {
    return !!this.user && ((this.user?.organisation_roles.indexOf('implementation-officer') > -1) ||
        (this.user?.organisation_roles.indexOf('implementation-creator') > -1))
  }

  private readNotifications(): void {
    this.user?.notifications.readAllNotifications()
  }

  private async logout(): Promise<void> {
    stateModule.setLoading(true)
    try {
      await userModule.logout()
      this.$router.push({ name: 'login' })
    } finally {
      stateModule.setLoading(false)
    }
  }
}
