











import { createSocket } from '@/api'
import { Component, Vue } from 'vue-property-decorator'

import { stateModule } from '@/store'

import Loader from '@/components/loaders/Loader.vue'
import FeedbackForm from './partials/FeedbackForm.vue'
import Navigation from '@/components/partials/Navigation.vue'
import { Socket } from 'socket.io-client'

@Component({
  components: {
    Loader,
    Navigation,
    FeedbackForm
  },
})
export default class Dashboard extends Vue {
  private socket?: Socket | null = null

  private get loading(): boolean {
    return stateModule.loading
  }

  private async created(): Promise<void> {
    try {
      const data: { channel: string, version: string } = await stateModule.checkVersion()
      this.openSocket(data.channel)
      this.compareVersion()
    } catch (e) {
      console.error(e)
    }
  }

  private async handleEvent(): Promise<void> {
    await stateModule.checkVersion()
    this.compareVersion()
  }

  private compareVersion(): void {
    if (stateModule.isNewVersionAvailable) {
      stateModule.setNotification({ message: 'A new version of Sparq is available, Please refresh to update.', action: { action: () => { window.location.reload() }, name: 'Click here to refresh' } })
    }
  }

  private async openSocket(channel: string): Promise<void> {
    this.socket = await createSocket(channel)
    this.socket?.on('event', (e: EventResponse) => this.handleEvent())
  }
}
